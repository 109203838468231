<template>
    <div class="xw_page_container" v-if="!pageLoading && tabList.length != 0">
        <div class="member_header">
            <div class="member_title_name " v-if="$store.state.windowWidth > 768">
                {{ $t('優惠活動') }}
            </div>
            <!-- <div class="search_item" v-if="$store.state.windowWidth > 768">
                <ul class="member_tab_list">
                    <li @click="tabActive = index" v-for="(item, index) in tabList" :key="index" class="member_tab_item"
                        :class="{ active: tabActive === index }">
                        {{ $t(item.c_title) }}
                    </li>
                </ul>
            </div> -->

            <div class="bg_header" v-if="$store.state.windowWidth <= 768">
                <div class="header_content">
                    <button @click="goBack" type="button" class="btn_back"></button>
                    <div class="page_title">{{ $t(tabList[tabActive].c_title) }}</div>
                </div>
            </div>
        </div>

        <!-- <div class="member_content" v-if="$store.state.windowWidth > 768">
            <div class="activity_content">
                <div v-html="tabList[tabActive].c_detail"></div>

                <div class="active_btn_wrap">
                    <el-button class="button_red" @click="knownClick">{{
                        tabList[tabActive].jump_url ? $t("去了解") : $t("已知晓")
                    }}</el-button>
                    <el-button class="button_blue"
                        v-if="tabList[tabActive].base_type == 5 || tabList[tabActive].auto_apply == 1"
                        @click="toLiveSuppert">{{
                            $t("联系客服") }}</el-button>
                    <template v-else>
                        <el-button class="button_blue" v-if="!isApplay" @click="apply(tabList[tabActive].id)">{{
                            $t("活动申请")
                        }}</el-button>
                        <el-button class="btn_gray" v-else>{{ $t("已申请") }}</el-button>
                    </template>
</div>

</div>

</div> -->

        <div class="activity_container">

            <div class="activity_mb" v-if="!detailShow">
                <div @click="changeAcTab(index)" v-for="(item, index) in tabList" :key="index" class="activity_mb_item">
                    <img :src="item.c_img" :alt="item.c_title">
                </div>
            </div>
            <div class="member_content" v-else>
                <div class="back_arrow" @click="goBack">返回活動</div>
                <div class="activity_content">
                    <div v-html="tabList[tabActive].c_detail"></div>

                    <div class="active_btn_wrap">
                        <el-button class="button_red" v-if="tabList[tabActive].jump_url" @click="knownClick">{{
                            tabList[tabActive].jump_url=='/singin' ? $t("去簽到") : $t("去了解")
                            }}</el-button>
                        <el-button class="button_red" v-else @click="knownClick">{{
                            $t("已知晓")
                            }}</el-button>
                        <el-button class="button_blue"
                            v-if="tabList[tabActive].base_type == 5 || tabList[tabActive].auto_apply == 1"
                            @click="toLiveSuppert">{{
                                $t("联系客服") }}</el-button>
                        <template v-else>
                            <el-button class="button_blue" v-if="!isApplay" v-loading="applayLoading"
                                @click="apply(tabList[tabActive].id)">{{
                                    $t("活动申请")
                                }}</el-button>
                            <el-button class="btn_gray" v-else>{{ $t("已申请") }}</el-button>
                        </template>
                    </div>
                </div>
            </div>
        </div>


        <xwTabbar v-if="$store.state.windowWidth <= 768" />

    </div>
</template>

<script>
import deposit from "./xwComponents/deposit.vue";
import xwWithdrawal from "./xwComponents/xwWithdrawal.vue";
import transactionHistory from "./xwComponents/transactionHistory.vue";
import xwInformation from "./xwComponents/xwInformation.vue";
import { action_list_api, action_nologin_list_api, action_apply_api } from "@/api/action";
import xwTabbar from "@/components/xwTabbar.vue";
import { serviceLink } from "@/utils/serviceLink.js";

export default {
    components: {
        deposit,
        xwWithdrawal,
        xwTabbar,
        transactionHistory,
        xwInformation
    },
    data() {
        return {
            tabActive: 0,
            tabList: [],
            pageLoading: true,
            isApplay: false,
            detailShow: false,
            applayLoading: false
        }
    },
    mounted() {
        this.getActionList()
    },
    methods: {
        changeAcTab(key) {
            this.tabActive = key
            this.detailShow = true
        },
        toLiveSuppert() {
            const ua = navigator.userAgent;
            let service_url = serviceLink.line;
            if (/android/i.test(ua) || /iphone|ipad|ipod/i.test(ua)) {
                if (/android/i.test(ua)) {
                    window.open(service_url, "_blank");
                } else {
                    window.location.href = service_url;
                }
            } else {
                window.open(service_url, "_blank");
            }
        },
        knownClick() {
            if (this.tabList[this.tabActive].jump_url) {
                this.$router.push(this.tabList[this.tabActive].jump_url);
            } else {
                this.goBack()
            }
        },
        async apply(id) {
            if (this.applayLoading) return
            this.applayLoading = true
            let data = {
                action_id: id,
            };
            await action_apply_api(data).then((res) => {
                if (res && res.data.code == 1) {
                    this.successTips(res.data.msg, {
                        showClose: false,
                        duration: "2000",
                        onClose: () => {
                            this.isApplay = true;
                        },
                    });
                } else if (res && res.data.code == 1000) {
                    this.errorTips(res.data.msg, {
                        showClose: false,
                        duration: "2000",
                        onClose: () => {

                        },
                    });
                } else {
                    this.errorTips(res.data.msg);
                }
                this.applayLoading = false
            });
        },
        goBack() {
            if (this.detailShow) {
                this.detailShow = false
            } else {
                this.$router.push({ path: '/' })
            }
        },
        async getActionList(is_hot) {
            let params = {
                page: 1,
                limit: 99,
            };
            let response
            if (this.$store.state.activityShow && !this.$store.state.isLoginStatu) {
                response = await action_nologin_list_api(params)
            } else {
                response = await action_list_api(params)
            }
            // const response = await action_list_api(params)
            if (response.data.code == 1) {
                console.log(response, 'responseresponse');
                this.tabList = response.data.data
                if (this.tabList.length == 0) {
                    this.$xwDialog.open({
                        title: this.$t('訊息'),
                        content: this.$t('暫無活動，請聯絡客服新增'),
                        hasClose: false,
                        onConfirm: () => {
                            this.$router.push({ path: '/' })
                        }
                    });
                } else {
                    if (this.$route.query.id) {
                        this.detailShow = true
                        this.tabActive = Math.max(this.tabList.findIndex(item => item.id == this.$route.query.id), 0)
                    }
                }
            }
            this.pageLoading = false
        },
    }
}
</script>

<style lang="less" scoped>
.xw_page_container {
    width: 100vw;
    min-height: var(--vh);
    background-color: #ebebeb;

    @media (max-width:768px) {
        background-color: #fff;
    }
}

.member_header {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: #3a9b7c;

    @media (max-width:768px) {
        position: fixed;
        top: 0;
        left: 0;
        height: 40px;
        background-color: #3f9eff;
        z-index: 10;
    }
}

.member_title_name {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    margin-left: 34px;
}

.search_item {
    margin-left: 0;
    height: 0;
    position: absolute;
    top: 24px;
    left: 179px;
    width: max-content;
}

.member_tab_list {
    list-style: none;
    display: flex;
    align-items: center;
    user-select: none;
    overflow-x: auto;
}

.member_tab_item {
    min-width: auto;
    padding: 0 17px;
    text-align: center;
    height: 36px;
    line-height: 36px;
    width: max-content;
    font-size: 13px;
    font-weight: normal;
    color: #fff;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
}

.member_tab_item.active {
    background-color: #ebebeb !important;
    color: #3a9b7c;
}

.bg_header {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 99;
}

.header_content {
    position: relative;
    color: #fff;
    width: 100%;
    height: 100%;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    font-weight: bold;
}

.page_title {
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 auto;
}

.btn_back {
    position: absolute;
    top: 0;
    left: 15px;
    bottom: 0;
    cursor: pointer;
    width: auto;
    height: 40px;
    display: block;
    background: url('../../assets/images/xw/btn_back.svg') no-repeat center;
    background-size: auto 40%;
}

.member_content {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 15px;
    color: #000;

    @media (max-width:768px) {
        padding: 40px 0 52px;
    }
}

.activity_content {
    overflow: auto;
    background-color: #fff;
    height: fit-content;
    min-height: 360px;
    border: 1px solid #dedede;
    padding: 8px;

    @media (max-width:768px) {
        border: none;
    }
}

.active_btn_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 20px 0px;
}

::v-deep table tbody td {
    position: relative;
    vertical-align: top;
    padding: 18px 8px;
    border: 1px solid #cdcdcd;
    color: #000 !important;
}

.activity_container {}

.activity_mb {
    color: #000;
    padding: 60px 24px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 900px;
    margin: 0 auto;
    gap: 20px;
}

.activity_mb_item {
    background-color: #ddd;
    min-height: 120px;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - 10px);

    @media (max-width:768px) {
        width: 100%;
    }

    // min-width: 375px;
    img {
        width: 100%;
        min-width: 100%;
        height: auto;
        min-height: 100%;
    }
}

.back_arrow {
    padding: 10px 0;
    text-decoration: underline;
    cursor: pointer;
    color: #3f9eff;

    &:hover {
        color: #71b2f2;
    }

    @media (max-width:768px) {
        display: none;
    }
}

.button_blue {
    overflow: hidden;
}
</style>